import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import logo from "../../logo.png";

import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Button, Row, Col, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Grid from "@material-ui/core/Grid";
import RoomIcon from "@material-ui/icons/Room";
import { blue, green, yellow } from "@material-ui/core/colors";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PersonIcon from "@material-ui/icons/Person";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import Geocode from "react-geocode";
// ES6 import
import io from "socket.io-client";
import axios from "axios";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Dropdown, Selection } from "react-dropdown-now";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "bootstrap/dist/css/bootstrap.css";
import "react-dropdown-now/style.css";
import Loader from "react-loader-spinner";
import PhoneInput from 'react-phone-number-input/input'

import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

// Geocode.setApiKey("AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s");

const override = css`
  display: block;
  margin: 0 auto;
  margin-left: 300px !important;
  border-color: 36d7b7;
`;

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "transparent",
  },
  menuButton: {
    color: "#B3B7C7",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  formControl: {
    // width: "100%",
    margin: 10,
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  noBorder: {
    border: "none",
  },
  margin: {
    margin: 10,
  },
  formAccordion: {
    backgroundColor: "transparent !important",
    border: "none !important",
  },
}));

export default function QrCodePage() {
  let [loading, setLoading] = React.useState(false);
  let [pageLoader, setPageLoader] = React.useState(false);
  const instance = axios.create();
  let [color, setColor] = React.useState("#36D7B7");
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [code, setCode] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [newUser, setNewUser] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [estimate, setEstimate] = React.useState(0);
  const [displacement, setDisplacement] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [waitTime, setWaitTime] = React.useState(0);
  const [priceEstimateUrl, setpriceEstimateUrl] = React.useState(
    process.env.REACT_APP_API_METER_ENDPOINT ||
      "https://meter-api.beam.live"
  );
  const [latLng, setlatLng] = React.useState({
    lat: 32.732346,
    long: -117.196053,
  });
  const searchOptions = {
    location: new window.google.maps.LatLng(32.732346, -117.196053),
    radius: 320000,
    types: ["address"],
  };
  const [peopleNo, setPeopleNo] = React.useState("1");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [luggageNo, setLuggageNo] = React.useState("0");
  let [scheduleTime, setScheduleTime] = React.useState(new Date());
  let [pickUpLocation, setPickUpLocation] = React.useState("San Diego Airport");
  const [instructions, setInstructions] = React.useState("");
  const [nameError, setNameError] = React.useState(null);
  const [disable, setDisabled] = React.useState(false);
  const firstRender = React.useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    formValidation();
    // setDisabled();
  }, [peopleNo, luggageNo, address]);
  useEffect(() => {
    // setpriceEstimateUrl(process.env.REACT_APP_API_GPS_ENDPOINT);
    if (latLng.lng && latLng.lat) {
      getPriceEstimate();
    }
  }, [latLng]);

  const formValidation = () => {
    console.log(phoneNo);
    if (phoneNo === "") {
      let temp = [];
      // temp.push(phoneNo === "" ? "Phone Number" : "");
      console.log("setNameError");
      setNameError("Phone number is mandatory");
      return true;
    } else {
      setNameError(null);
      return false;
    }
  };

  let dropOffLat = "";
  let dropOffLng = "";
  const toggleEvent = (e) => {
    console.log("=== toggle event function call ==");
  };

  const handleSelect = (address) => {
    console.log("complete address is : ", address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((response) => {
        setAddress(address);
        console.log(response);
        setlatLng(response);
      })
      .catch((error) => console.error("Error", error));
  };

  async function getPriceEstimate() {
    await instance
      .post(priceEstimateUrl + "/api/v1/meter/ride/estimation", {
        start: {
          lat: "32.732346",
          long: "-117.196053",
        },
        end: {
          lat: latLng.lat.toString(),
          long: latLng.lng.toString(),
        },
        startTime: new Date().toISOString(),
      })
      .then((res) => {
        if (res.data.status) {
          var meta_data = res.data?.meta_data;
          console.log("estimate response:", { meta_data });
          setEstimate(Math.round(meta_data.estimate));
          setDisplacement(Math.round(Math.round(meta_data.distance / 1000) /  1.609));
          setDuration(Math.round(meta_data.duration / 60));
          // setWaitTime(parseFloat(meta_data.waitTime / 60));
        }
      });
  }

  async function requestSmsToken() {
    let url =
      process.env.REACT_APP_BEAM_API_LIVE_ENDPOINT ||
      "https://page-api.beam.live";
    await instance
      .post(url + "/api/v1/validate-pin", {
        phone: phoneNo,
        pin: code,
      })
      .then((res) => {
        console.log("requestSmsToken", res);
        if (res.data.status) {
          var meta_data = res.data?.meta_data;
          console.log("estimate response:", { meta_data });
          setEstimate(Math.round(meta_data.estimate));
          setDisplacement(Math.round(Math.round(meta_data.distance / 1000) /  1.609));
          setDuration(Math.round(meta_data.duration / 60));
          // setWaitTime(parseFloat(meta_data.waitTime / 60));
        }
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = formValidation();
    console.log({ valid }, nameError);
    if (!valid) {
      var passengerInfo = {};

      var selectedTime = scheduleTime["value"];
      setPageLoader(true);
      if (scheduleTime["value"]) {
        selectedTime = scheduleTime["value"];
        selectedTime = selectedTime.split(" ")[0];
      } else if (scheduleTime["value"] == "+30mins") {
        selectedTime = 30;
        selectedTime = selectedTime.split(" ")[0];
      } else {
        selectedTime = 15;
      }
      var today = new Date();
      console.log(today);
      today.setTime(today.getTime() + selectedTime * 60 * 1000);
      var updatedIsoTime = today.toISOString();
      console.log(updatedIsoTime);

      let pickUpLat = 32.7338;
      let pickUplng = -117.1933;

      let pickUpLocation = "SanDiego Airport";
      let dropOfLocation = address;
      var priceEstimate = 0;
      console.log(process.env.REACT_APP_API_LIVE_ENDPOINT);
      var registerUrl =
        process.env.REACT_APP_API_LIVE_ENDPOINT + "/v2/booking/new";

      var password = Math.floor(10000 + Math.random() * 90000).toString();
      var userEmail = "testRider" + phoneNo + "@app.com";
      console.log("=== ride price is :", estimate);
      console.log("estimate", { estimate });
      let payload = {
        username: phoneNo,
        password: password,
        email: phoneNo,
        phoneNumber: phoneNo,
        termsandcondition: true,
        beamId: phoneNo,
        admin: false,
        imei: true,
        title: "RideBooking-" + new Date(),
        pageData: {
          submit: true,
          pickupLat: pickUpLat,
          pickupLong: pickUplng,
          pickupLocation: pickUpLocation,
          rideType: "regular",
          scheduleTime: updatedIsoTime,
          dropoffLat: latLng["lat"] || 0,
          dropoffLong: latLng["lng"] || 0,
          dropOfLocation: dropOfLocation,
          fixedPrice: false,
          monitor: false,
          numPassengers: peopleNo,
          numBaggages: luggageNo,
          instructions: instructions,
          estimate: parseFloat(estimate),
        },
        profile: { rideType: "taxi" },
      };
      await instance
        .post(registerUrl, payload)
        .then((res) => {
          console.log({ res });
          var pageId = res.data["meta_data"]["pageId"];
          var pageUrl = res.data["meta_data"]["pageUrl"];

          // setModal(true);
          if (res.data.meta_data?.passengerObj?.verifiedPhone === false) {
            setModal(true);
            setNewUser(true);
            setPageLoader(false);
          } else {
            setPageLoader(false);

            var pageId = res.data["meta_data"]["pageId"];
            var pageUrl = res.data["meta_data"]["pageUrl"];
            //console.log("response is: ",res);
            console.log("=== api response data is: ", res.data["meta_data"]);
            var userAccessToken = res.data["meta_data"]["accessToken"];
            var passenger = res.data["meta_data"]["passengerObj"];
            console.log("=== passenger is :", passenger);
            console.log("=== access token is : ", userAccessToken);
            passengerInfo = {
              requestType: "CAD",
              mobileNumber: phoneNo,
              altMobileNumber: "",
              passengerName: "",
              lastName: "",
              email: phoneNo,
              scheduledTimes: [updatedIsoTime],
              monitor: false,
              rideType: "regular",
              features: [],
              fleets: [],
              numPassengers: peopleNo,
              numBaggages: luggageNo,
              driverGender: "",
              instructions: instructions,
              pickupLat: pickUpLat,
              pickupLong: pickUplng,
              pickupLocation: pickUpLocation,
              dropoffLat: latLng["lat"] || 0,
              dropoffLong: latLng["lng"] || 0,
              dropoffLocation: dropOfLocation,
              estimate: estimate,
              submit: true,
              dontCreatePage: false,
              vehicles: null,
              accountNumber: "",
              accountName: "",
              bookerName: "",
              bookerEmail: "",
              bookerPhone: "",
              packages: [],
              paymentMethod: "",
              iotDevice: null,
              currentUserId: passenger._id,
              pageId: pageId,
            };
            console.log({ passengerInfo });
            setLoading(!loading);
            const socket = io.connect(process.env.REACT_APP_API_LIVE_ENDPOINT, {
              transports: ["websocket"],
              path: "/socket.io-v2",
              query: {
                accessToken: userAccessToken,
              },
            });
            socket.on("connect", () => {
              console.log("====connected to socket api live ===");
            });
            socket.emit(
              "ride/taxiRideSubmit",
              {
                submit: true,
                pickupLat: pickUpLat,
                pickupLong: pickUplng,
                scheduleTime: updatedIsoTime,
                dropoffLat: latLng["lat"],
                dropoffLong: latLng["lng"],
                fixedPrice: false,
                monitor: false,
                numPassengers: peopleNo,
                currentUserId: passenger._id,
                numBaggages: luggageNo,
                id: pageId,
                estimate: estimate,
              },
              function (error, message) {
                console.log("=== new disconnecting from server ===");
                // socket.disconnect();
                // console.log("=== socket disconnected ===");
              }
            );
            //socket.disconnect();
            console.log("=== socket disconnected ===");
            //var socketUrl = '`https://cors-anywhere.herokuapp.com/'+process.env.REACT_APP_API_METER_ENDPOINT
            const meterSocket = io.connect(
              process.env.REACT_APP_API_METER_SOCKET_ENDPOINT,
              {
                transports: ["websocket"],
                query: {
                  accessToken: userAccessToken,
                },
              }
            );

            meterSocket.on("connect", () => {
              console.log("==== meter socket connected ===");
            });

            meterSocket.emit("addPassengerQueue", {
              pageId: pageId,
              passenger: passengerInfo,
            });
            setPageLoader(false);

            setTimeout(function () {
              //your code to be executed after 1 second
              setPageLoader(false);
              if(newUser) {
                window.location.assign(pageUrl ? pageUrl : "http://ride.beam.live/#/page/payment");
              } else {
                window.location.assign(pageUrl ? pageUrl : "http://ride.beam.live/");

              }
            }, 3000);
          }
        })
        .catch((e) => {
          console.log("error", e);
          setNameError("Something went wrong!");
          setPageLoader(false);
        });
    }
  };

  const verifyNumber = async (data) => {
    // e.preventDefault();
    console.log({ data });
    // let valid = formValidation();
    // console.log({ valid }, nameError);
    if (data.pinCode.length === 4) {
      var passengerInfo = {};

      var selectedTime = scheduleTime["value"];
      setPageLoader(true);
      if (scheduleTime["value"]) {
        selectedTime = scheduleTime["value"];
        selectedTime = selectedTime.split(" ")[0];
      } else if (scheduleTime["value"] == "+30mins") {
        selectedTime = 30;
        selectedTime = selectedTime.split(" ")[0];
      } else {
        selectedTime = 15;
      }
      var today = new Date();
      console.log(today);
      today.setTime(today.getTime() + selectedTime * 60 * 1000);
      var updatedIsoTime = today.toISOString();
      console.log(updatedIsoTime);

      let pickUpLat = 32.7338;
      let pickUplng = -117.1933;

      let pickUpLocation = "SanDiego Airport";
      let dropOfLocation = address;
      var priceEstimate = 0;
      console.log(process.env.REACT_APP_API_LIVE_ENDPOINT);
      var registerUrl =
        process.env.REACT_APP_API_LIVE_ENDPOINT + "/v2/booking/new";

      var password = Math.floor(10000 + Math.random() * 90000).toString();
      var userEmail = "testRider" + phoneNo + "@app.com";
      console.log("=== ride price is :", estimate);
      console.log("estimate", { estimate });
      let payload = {
        username: phoneNo,
        password: password,
        email: phoneNo,
        phoneNumber: phoneNo,
        termsandcondition: true,
        beamId: phoneNo,
        admin: false,
        imei: true,
        title: "RideBooking-" + new Date(),
        pageData: {
          submit: true,
          pickupLat: pickUpLat,
          pickupLong: pickUplng,
          pickupLocation: pickUpLocation,
          rideType: "regular",
          scheduleTime: updatedIsoTime,
          dropoffLat: latLng["lat"] || 0,
          dropoffLong: latLng["lng"] || 0,
          dropOfLocation: dropOfLocation,
          fixedPrice: false,
          monitor: false,
          numPassengers: peopleNo,
          numBaggages: luggageNo,
          instructions: instructions,
          estimate: parseFloat(estimate),
        },
        profile: { rideType: "taxi" },
        ...data,
      };
      await instance
        .post(registerUrl, payload)
        .then((res) => {
          setModal(false);

          var pageId = res.data["meta_data"]["pageId"];
          var pageUrl = res.data["meta_data"]["pageUrl"];

          //console.log("response is: ",res);
          console.log("=== api response data is: ", res.data["meta_data"]);
          var userAccessToken = res.data["meta_data"]["accessToken"];
          var passenger = res.data["meta_data"]["passengerObj"];
          console.log("=== passenger is :", passenger);
          console.log("=== access token is : ", userAccessToken);
          // debugger
          passengerInfo = {
            requestType: "CAD",
            mobileNumber: phoneNo,
            altMobileNumber: "",
            passengerName: "",
            lastName: "",
            email: userEmail,
            scheduledTimes: [updatedIsoTime],
            monitor: false,
            rideType: "regular",
            features: [],
            fleets: [],
            numPassengers: peopleNo,
            numBaggages: luggageNo,
            driverGender: "",
            instructions: instructions,
            pickupLat: pickUpLat,
            pickupLong: pickUplng,
            pickupLocation: pickUpLocation,
            dropoffLat: latLng["lat"] || 0,
            dropoffLong: latLng["lng"] || 0,
            dropoffLocation: dropOfLocation,
            estimate: estimate,
            submit: true,
            dontCreatePage: false,
            vehicles: null,
            accountNumber: "",
            accountName: "",
            bookerName: "",
            bookerEmail: "",
            bookerPhone: "",
            packages: [],
            paymentMethod: "",
            iotDevice: null,
            currentUserId: passenger.userId || passenger._id,
            pageId: pageId,
          };
          console.log({ passengerInfo });
          setLoading(!loading);
          const socket = io.connect(process.env.REACT_APP_API_LIVE_ENDPOINT, {
            transports: ["websocket"],
            path: "/socket.io-v2",
            query: {
              accessToken: userAccessToken,
            },
          });
          socket.on("connect", () => {
            console.log("====connected to socket api live ===");
          });
          setNewUser(true)
          socket.emit(
            "ride/taxiRideSubmit",
            {
              submit: true,
              pickupLat: pickUpLat,
              pickupLong: pickUplng,
              scheduleTime: updatedIsoTime,
              dropoffLat: latLng["lat"],
              dropoffLong: latLng["lng"],
              fixedPrice: false,
              monitor: false,
              numPassengers: peopleNo,
              numBaggages: luggageNo,
              id: pageId,
              estimate: estimate,
            },
            function (error, message) {
              console.log("=== new disconnecting from server ===");
              // socket.disconnect();
              // console.log("=== socket disconnected ===");
            }
          );
          //socket.disconnect();
          console.log("=== socket disconnected ===");
          //var socketUrl = '`https://cors-anywhere.herokuapp.com/'+process.env.REACT_APP_API_METER_ENDPOINT
          const meterSocket = io.connect(
            process.env.REACT_APP_API_METER_SOCKET_ENDPOINT,
            {
              transports: ["websocket"],
              query: {
                accessToken: userAccessToken,
              },
            }
          );

          meterSocket.on("connect", () => {
            console.log("==== meter socket connected ===");
          });

          meterSocket.emit("addPassengerQueue", {
            pageId: pageId,
            passenger: passengerInfo,
          });

          setTimeout(function () {
            //your code to be executed after 1 second
            setPageLoader(false);
            if(newUser) {
              window.location.assign(pageUrl ? pageUrl : "http://ride.beam.live/#/page/payment");
            } else {
              window.location.assign(pageUrl ? pageUrl : "http://ride.beam.live/");
            }
          }, 3000);
          return {
            status: true,
            message: "Succesfull",
          };
        })
        .catch((e) => {
          console.log("error", e);
          setPageLoader(false);
          setModal(true);
          setNewUser(true);

          return {
            status: false,
            message: e,
          };
        });
    } else {
      return {
        status: false,
        message: "Please enter 4 digit code!",
      };
    }
  };
  function PhoneVerifyForm({ display = true, onSubmit }) {
    const [show, setShow] = useState(display);
    const [error, setError] = useState(display);
    const [pinCode, setPinCode] = useState("");

    const handleClose = async () => {
      let res = await onSubmit({ pinCode });
      // console.log({res});
      // if (res.status === true) {
      //   setError("");
      //   setShow(false);
      // } else {
      //   setError(res.message);
      // }
    };
    const handleShow = () => {
      // setShow(true)
    };

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Phone Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && error.length > 0 && (
              <Alert variant="danger">
                <Alert.Heading varient="">{error}</Alert.Heading>
              </Alert>
            )}
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">
                Please enter 4 digit code
              </label>
              <FormControl
                placeholder="4 Digit Code"
                aria-label="4 Digit Code"
                aria-describedby="basic-addon1"
                onChange={(e) => setPinCode(e.target.value)}
                type="number"
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Verify!
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return (
    <React.Fragment>
      <script
        async
        defer
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s&libraries=places&callback=myCallbackFunc"
      ></script>
      <meta
        name="apple-itunes-app"
        content="app-id=id1570276190, app-argument=myURL"
      ></meta>
      <CssBaseline />

      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar} aria-label="menu">
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            <img src={logo} alt="Logo" className="beamLogo" />
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ErrorOutlineIcon />
          </IconButton>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}

      <div className="qrFormBody">
        <Container className="container">
          <Row>
            <Col>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                className="qrHeader"
              >
                QR Code Passenger Booking
              </Typography>
              <div className="qrCard sweet-loading">
                <form
                  className="qrForm"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  {nameError && (
                    <Alert variant="danger">
                      <Alert.Heading varient="">{nameError}</Alert.Heading>
                    </Alert>
                  )}
                  {pageLoader && pageLoader === true && (
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      className="formLoader"
                    >
                      <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={130}
                        width={130}
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <RoomIcon
                        style={{ color: green[500], fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Pickup Location</Typography>
                      <input
                        type="text"
                        className="input-text"
                        placeholder="Departure gate A5"
                        value={pickUpLocation}
                        onChange={(event) =>
                          setPickUpLocation(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <WatchLaterIcon
                        style={{ color: "#FBAE16", fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography noWrap> Estimated Pick Up Time</Typography>
                      {/* <TimePicker
                          onChange ={setScheduleTime}
                          value = {scheduleTime}
                        /> */}
                      <Dropdown
                        className="dropdownPickUp"
                        placeholder="15 mins"
                        //options={['5 mins', '10 mins', '15 mins', '20 mins', '+30 mins']}
                        options={["15 mins", "20 mins", "+30 mins"]}
                        value={scheduleTime}
                        onChange={(value) =>
                          console.log(" === new value that is changed ", value)
                        }
                        onSelect={setScheduleTime}
                        onClose={(closedBySelection) =>
                          console.log("closedBySelection?:", closedBySelection)
                        }
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <PhoneIphoneIcon
                        style={{ color: blue[500], fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>

                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Mobile Phone</Typography>
                      <PhoneInput
                          country="US"
                          className="input-text"
                          placeholder="222 333 4444"
                          value={phoneNo}
                          required
                          onChange={(value) => {
                            if(value && value.length <=12) {
                              setPhoneNo(value)
                            } else {
                              setPhoneNo(phoneNo)

                            }
                          }} />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <RoomIcon
                        style={{ color: "#FBAE16", fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Destination</Typography>
                      <PlacesAutocomplete
                        required
                        value={address}
                        onChange={(newAddress) => {
                          setAddress(newAddress);
                        }}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              style={{
                                border: "aliceblue",
                                background: "transparent",
                              }}
                              {...getInputProps({
                                placeholder: "Destination",
                                className: "location-search-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading please wait...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#c5cdd9",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#eff2f7",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <PersonIcon
                        style={{ color: green[500], fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Number of People</Typography>
                      <input
                        type="number"
                        className="input-text"
                        placeholder="0"
                        value={peopleNo}
                        required
                        min="1"
                        onChange={(event) => setPeopleNo(event.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item alignItems="center" justify="center">
                      <LocalMallIcon
                        style={{ color: blue[500], fontSize: 40 }}
                        className="Icon"
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Number of Luggage</Typography>
                      <input
                        type="number"
                        className="input-text"
                        placeholder="0"
                        required
                        min="1"
                        value={luggageNo}
                        onChange={(event) => setLuggageNo(event.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    wrap="nowrap"
                    spacing={2}
                    className="formFieldQr"
                  >
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>Special Instruction </Typography>
                      <textarea
                        type="text"
                        value={instructions}
                        className="input-text"
                        rows="4"
                        cols="50"
                        placeholder=""
                        onChange={(event) =>
                          setInstructions(event.target.value)
                        }
                      />
                      <span className="left">Optional </span>
                      <span className="right">max. 500 character </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="estimateGrid">
                    <Grid item xs={12} columns={12} wrap="nowrap">
                      <h6 className="estimateGridTitle">Estimation</h6>
                    </Grid>
                    <Grid item xs={4} columns={4}>
                      <p className="estimateColTitle">Distance</p>
                      <p className="estimateColValue">{displacement} mi</p>
                    </Grid>
                    <Grid item xs={4} columns={4}>
                      <p className="estimateColTitle">Time</p>
                      <p className="estimateColValue"> {duration} min</p>
                    </Grid>
                    <Grid item xs={4} columns={4}>
                      <p estimateColTitle>Fare</p>
                      <p className="estimateColValue">${estimate}</p>
                    </Grid>
                  </Grid>
                  <input
                    disabled={disable}
                    type="submit"
                    className="btn btn-success fullWidth submit"
                  />
                </form>
                <PhoneVerifyForm display={modal} onSubmit={verifyNumber} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* End footer */}
    </React.Fragment>
  );
}
