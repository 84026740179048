import './App.scss';
import QrCodePage from './Component/qrCodeMobile/QrCodePage';

function App() {
  return (
    <div className="App">
      <QrCodePage />
    </div>
  );
}

export default App;
